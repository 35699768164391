/* global google */
import React, { useState, useEffect, useRef, useCallback  }  from "react";
import Header from '../../components/header';
import Navigator from '../../components/navigator'; 
import LoadUser from '../../components/loadUser'
//MUI - COMPONENTS
import Grid from "@material-ui/core/Grid"; 
import Typography from "@material-ui/core/Typography"; 
import Container from "@material-ui/core/Container"; 
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer'; 
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';  
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
//CALCULATE DISTANCE BY COORS
import getDistance from '../../lib/distance';
//FIRESTORE
import { Db } from '../../firebase'
//MOMENT DATETIME
import moment from 'moment';
//MAPS
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, Circle,  } from '@react-google-maps/api';
//USER CONSTEXT
import { useUser } from '../../context/auth'
import { useMarket } from '../../context/market'
//IMG
import ciduMarket from '../../assets/img/ciduMarket.svg'
//STYLES
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useStyles from './style.js'

const libraries = ['places'];

const initialState = {
   img: 'https://picsum.photos/700/700',
   projectName: 'Lorem Ipsum', 
   projectType: 'ninguno',
   projectClass: 'N/A',
   //projectDate: '01/01/01',
   projectValue: '123.456.000',
   //projectVetustez: '10',
   projectArea: '123', 
   //projectAutor: 'Pepito Perez'
}

export default function Dashboard() {

  const mapRef = useRef(null) 
  const mapCircle = useRef(null) 
  const classes = useStyles();  
  const theme = useTheme();
  //const googleMa = useGoogleMap()
  const { coordinates, setCoordinates, marketData } = useMarket(); 
  const { loadingUser } = useUser();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isMobile, setIsMobile] = useState(false)
  const [map, setMap] = useState(null) 
  const [projects, setProjects] = useState([])
  const [insideProjects, setInsideProjects] = useState([])
  const [aboutCidu, setAboutCidu] = useState(false)
  const [showCoords, setShowCoords] = useState(false)
  const [viewCard, setViewCard] = useState(false)
  const [infoCard, setInfoCard] = useState(initialState)
  

  useEffect(() => {
    const unsubscribe = Db.collection("ciduRepository").onSnapshot(snap => {
      const project = [];
      var countProject = 0;
      snap.forEach(doc => {
        const dataProject = doc.data() 
        const isDefaultProject = dataProject.defaultProject 
        if(!isDefaultProject){
          const projectImg = dataProject.projectImg;
          const projectValue = dataProject.projectValue;
          const projectArea = dataProject.projectArea; 
          const typeProperty = dataProject.typeProperty;
          const numOld = dataProject.numOld;
          const projectCoords = dataProject.projectCoords;
          const pojectName = dataProject.nameProject;
          const projectAuthor = dataProject.projectAuthor;
          const projectId = dataProject.projectId;
          const projectProperty = dataProject.projectProperty;
          const finished = moment(dataProject.finished.toDate()).format('L')
          const id = (++countProject).toString()
          project.push({
            projectImg, 
            projectValue,
            projectArea,
            typeProperty,
            numOld,
            projectCoords,
            pojectName,
            projectAuthor,
            projectId,
            projectProperty,
            finished, 
            id,
          })
        }
      });
      //console.log('project', project)
      setProjects(project)
    });
    
 
    return () => unsubscribe()
  }, [])


  useEffect(() => {
    handleChangeCircle()
  }, [marketData.class, marketData.type, marketData.area, marketData.age])


  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_API_KEY_GOOGLEMAPS,
    libraries: libraries
  })

  const handleProjectCard = project => {
    try{
      setInfoCard(prevState => ({
        ...prevState, 
        img: project.projectImg,
        projectName: project.pojectName,
        projectType: project.typeProperty,
        projectClass: project.projectProperty,
        projectValue: project.projectValue,
        projectArea: project.projectArea,
      }))
      setViewCard(true)
    }catch(e){
      console.log('Error to view project: ', e)
    } 
  }

  const handleCardClose = () => {
    try{ 
      hangleToggleCard()
      setInfoCard(initialState) 
    }catch(e){
      console.log('Error to close project card: ', e)
    }  
  }

  const hangleToggleCard = () => setViewCard(!viewCard)
  
  //OK TOGGLE MODAL RIGHT DRAWER
  const handleMobile = () => setIsMobile(!isMobile)  

  //OK GET NEW COORDS BY CLICK
  const handleClickCoords = async (e) => {
    //console.log('coords', e) 
    const coords = e.latLng
    const lat = await coords.lat()
    const lng = await coords.lng()
    //console.log('lat - lng', lat, lng)
    setCoordinates(() => ({
      lat,
      lng
    }))
  }
 
  //OK OPEN INFO CARD ABOUT CIDU S.A.S
  const hangleOpenCiduInfo = () => setAboutCidu(!aboutCidu)

  //OK OPEN INFO CARD ABOUT PROJECTS
  const hangleOpenCircleInfo = () => setShowCoords(!showCoords) 

  //OK UNMMOUNT COMPONENTS
  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  //OK CHANGE RADIOUS POSITION
  const handleChangeCircle = async () => { 
    try{
      const viewProjects = [];
      projects.forEach(p => {
        const distance = getDistance(coordinates.lat, coordinates.lng, p.projectCoords.latitude, p.projectCoords.longitude)
        const maxDistance = marketData.ratio;
        const projectProperty = marketData.class === 'todo' ? p.projectProperty: marketData.class;
        const typeProperty =  marketData.type === 'todo' ? p.typeProperty: marketData.type; 
        
        const minArea = marketData.area === 'one' ? 0 
          : marketData.area === 'two' ? 50
            : marketData.area === 'three' ? 100
              : marketData.area === 'four' ? 150 : 0
        const maxArea = marketData.area === 'one' ? 50
          : marketData.area === 'two' ? 100
            : marketData.area === 'three' ? 150
              : marketData.area === 'four' ? 10000000 : 10000000
        const currentArea = parseFloat(p.projectArea)
        //const algo = minArea <= currentArea && maxArea >= currentArea
        //console.log('minArea,', minArea, 'maxArea', maxArea, 'algo', algo, 'p.projectArea', currentArea)

        const minAge = marketData.age === 'one' ? 0
          : marketData.age === 'two' ? 5
            : marketData.age === 'three' ? 10
              : marketData.age === 'four' ? 20 : 0
        const maxAge = marketData.age === 'one' ? 5
          : marketData.age === 'two' ? 10
            : marketData.age === 'three' ? 20
              : marketData.age === 'four' ? 10000000 : 10000000
        const currentAge = parseFloat(p.numOld)
        //const algo = minAge <= currentAge && maxAge >= currentAge
        //console.log('minAge,', minAge, 'maxAge', maxAge, 'algo', algo, 'p.projectAge', currentAge)
          
        if(distance <= maxDistance && p.projectProperty === projectProperty && typeProperty === p.typeProperty && minArea <= currentArea && maxArea >= currentArea && minAge <= currentAge && maxAge >= currentAge ) viewProjects.push(p)  
      })
      setInsideProjects(viewProjects)
    }catch(e){
      console.log('Error to calculate projects on radio: ', e)
    }
  }  
 
  if (loadingUser || !isLoaded) return <LoadUser />

  return ( 
    <div className={classes.root}>
      <Header onDrawerToggle={handleMobile} statusbar={isMobile} stateScreen={isDesktop} />
      <Navigator 
        open={isMobile}
        isDesktop={isDesktop}
        variant={isDesktop ? 'permanent' : 'temporary'} 
        onClose={handleMobile} />
      <main 
        className={classes.main}>
        <Grid className={classes.rootMap}>  

          {viewCard && <Card className={classes.rootCard}>
            <CardActionArea onClick={handleCardClose}>
              <CardMedia
                component="img"
                alt="Fachada"
                height="140"
                image={infoCard.img}
                title="Fachada" />
              <CardContent>
                <Typography gutterBottom variant="h5" noWrap component="h2">{infoCard.projectName}</Typography>
                <Typography variant="body2" color="textSecondary" noWrap component="p">{`Tipo: ${infoCard.projectType}`}</Typography>
                <Typography variant="body2" color="textSecondary" noWrap component="p">{`Clase: ${infoCard.projectClass}`}</Typography>
                <Typography variant="body2" color="textSecondary" noWrap component="p">{`Área: ${infoCard.projectArea} m\u00b2`}</Typography>
                <Typography variant="body2" color="textSecondary" noWrap component="p">{`Valor Unidad: $${infoCard.projectValue}`}</Typography>
              </CardContent>
            </CardActionArea> 
          </Card>}


          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: insideProjects.length > 0 ? '70%' : '100%'
            }}
            center={coordinates}  
            ref={mapRef}
            zoom={13} // 6.2
            options={{
              fullscreenControl: false,
              minZoom: 5,
              scaleControl: true,
              streetViewControl: false,
              mapTypeControl: false, 
            }}
            onClick={handleClickCoords} 
            onUnmount={onUnmount} >

              <Marker
                animation={window.google.maps.Animation.DROP}
                icon={ciduMarket}
                title={'CIDU S.A.S'} 
                onClick={hangleOpenCiduInfo}
                position={{ lat: 4.637608375012955, lng: -74.06252343155685}} > 
                {aboutCidu && <InfoWindow> 
                   <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <Typography noWrap variant="h6" component="h6" align={'center'} className={classes.infoView}>{`Consultoría de Ingeniería y Desarollo Urbano S.A.S`}</Typography>  
                    <Typography noWrap variant="subtitle1" component="h6" align={'center'} className={classes.infoView}>{`Información confiable para decisiones efectivas`}</Typography>  
                    <Typography noWrap variant="subtitle2" component="h6" align={'center'} className={classes.infoView}>{`Contacto: direccion@cidu.com.co`}</Typography>  
                    <Typography noWrap variant="subtitle2" component="h6" align={'center'} className={classes.infoView}>{`Teléfono: 301 460 69 80`}</Typography>  
                    <Typography noWrap variant="subtitle2" component="h6" align={'center'} className={classes.infoView}>{`Dirección: Calle 51a No. 6 - 30 Ofic. 603`}</Typography>  
                  </Grid>
                </InfoWindow>}
              </Marker>


            <Marker
              onClick={hangleOpenCircleInfo} 
              //label={'1'}
              animation={window.google.maps.Animation.DROP}
              title={`Lat: ${coordinates.lat} - Lng: ${coordinates.lng}`}
              position={coordinates}
              draggable={true}  
              onDragEnd={handleClickCoords} 
              name={"Current Position"} > 

              <Circle
                radius={marketData.ratio}
                center={coordinates} 
                onCenterChanged={handleChangeCircle}
                onRadiusChanged={handleChangeCircle}
                ref={mapCircle}
                options={{
                  strokeColor:'#33658a',
                  strokeOpacity:0.25,
                  strokeWeight:1,
                  fillColor:'#294374',
                  fillOpacity:0.35, 
                }} 
                />

              {showCoords && <InfoWindow> 
                <Typography className={classes.infoView}>{`Lat: ${coordinates.lat} - Lng: ${coordinates.lng}`}</Typography>  
              </InfoWindow>}
            </Marker>

            {projects.map(p => (  
              <Marker
                key={p.projectId}
                label={p.id}
                onClick={() => handleProjectCard(p)}
                position={{ lat: p.projectCoords.latitude, lng: p.projectCoords.longitude }}  
                animation={window.google.maps.Animation.DROP}
                name={p.pojectName} />
            ))}

            {/* <Marker
              position={{ lat: 4.677246757257592, lng: -74.07917976379395 }}  
              name={"Current location"} />
            <Marker
              position={{ lat: 4.689436793506394, lng: -74.10235404968262 }}  
              name={"Current location"} />
            <Marker
              position={{ lat: 4.679384, lng: -74.138492 }}  
              name={"Current location"} />
            <Marker
              position={{ lat:5.032244, lng: -75.570719 }} 
              name={"Current location"} />
            <Marker
              position={{ lat: 3.610164, lng: -76.549607 }}  
              name={"Current location"} />
            <Marker
              position={{ lat: 11.908843, lng: -71.702779 }}  
              name={"Current location"} />
            <Marker
              position={{ lat: 1.107259, lng: -77.468340 }}  
              name={"Current location"} /> */}
          </GoogleMap> 

          {insideProjects.length > 0 && <Grid className={classes.rootContainer}>
            <Container className={classes.tableContainer} maxWidth="lg"> 
              <TableContainer component={Paper} className={classes.table}>
                <Table className={classes.table} aria-label="simple table" size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell size='small' padding='checkbox'>Id</TableCell>
                      <TableCell size='small' >Consecutivo</TableCell>
                      <TableCell size='small' align="right">Tipo</TableCell>
                      <TableCell size='small' align="right">Clase</TableCell>
                      <TableCell size='small' align="right">Valor Ud.</TableCell>
                      <TableCell size='small' align="right">Fecha</TableCell>
                      <TableCell size='small' align="right">Vetustez</TableCell>
                      <TableCell size='small' align="right">{'Área m\u00b2'}</TableCell>
                      <TableCell size='small' align="right">Avaluador</TableCell> 
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {insideProjects.map(p => ( 
                      <TableRow key={p.projectId} hover onClick={() => handleProjectCard(p)} className={classes.row}>
                        <TableCell size='small' padding='checkbox'>{p.id}</TableCell>
                        <TableCell size='small' component="th" scope="row">{p.pojectName}</TableCell>
                        <TableCell size='small' align="right">{p.typeProperty}</TableCell>
                        <TableCell size='small' align="right">{p.projectProperty.toUpperCase()}</TableCell>
                        <TableCell size='small' align="right">{`$${p.projectValue}`}</TableCell>
                        <TableCell size='small' align="right">{p.finished}</TableCell>
                        <TableCell size='small' align="right">{p.numOld}</TableCell>
                        <TableCell size='small' align="right">{p.projectArea}</TableCell>
                        <TableCell size='small' align="right">{p.projectAuthor}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={classes.footerSpace}/>
            </Container>
          </Grid>}
        </Grid>

      </main>
    </div> 
  );
}  

// projectCoords: { 
//   latitude: 4.595124409976966
//   latitudeDelta : 0.007356042047025113
//   longitude: -74.12359731271863
//   longitudeDelta: 0.004922859370708466
// },