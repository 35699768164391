import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid'; 
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';  
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search'; 
//Maps
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
//IMG
import CiduLogo from '../../assets/img/ciduBlanco.png';
//CONTEXT 
import { useMarket } from '../../context/market'
//ESTILOS
import useStyles from './style.js';
 

export default function Header(props){

  const { onDrawerToggle, open } = props; 
  const inputSearch = useRef(null)
  const { coordinates, setCoordinates, updateMarket } = useMarket(); 
  const classes = useStyles();
  const [credentials, setCredentials] = useState(false) 
  const [address, setAddress] = useState('')
  
  
  //OK  // Toggle modal to credentials
  const handleMenu = () => setCredentials(!credentials) 

  //OK SELECTED SITE OR DIRECTION
  const handleSelect = async value => { 
    //console.log(inputSearch.current)
    //console.log('value', value)
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0])
    updateMarket(value)
    setAddress(value)
    setCoordinates(latLng)
    await inputSearch.current.blur()
  }

  //OK WHEN ENTER IS SELECT
  const handleKeySelect = async (value, event) => {
    //console.log('enter press here! ', event)
    if(event.key === 'Enter'){
      //e.target.blur();
      //console.log('enter press here! ', event)
      inputSearch.current.blur()
    }
  }
 
 
  return ( 
    <AppBar
      color="primary"
      position="sticky"
      className={clsx({[classes.appbarcololor]: true, [classes.appBarShift]: open})}
      elevation={0}>
      <Toolbar className={classes.toolbar} > 

            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => { onDrawerToggle(); handleMenu() }}
              className={classes.menuButton}>
              {open ?  <MenuIcon /> : <MenuOpenIcon />  }
            </IconButton>
            <div className={classes.headerSpace} />
            

            <Typography noWrap variant="h6" component="h4" className={classes.tittleSlide}> 
              {`Lat:  ${coordinates.lat}  -  Lng:  ${coordinates.lng}`}
            </Typography>


            <Grid className={classes.containerSearch}> 
              
              <Avatar alt="cidu" src={CiduLogo} className={classes.iconButtonAvatar} />

              <PlacesAutocomplete 
                searchOptions={{  
                  componentRestrictions: { country: "co" },
                  types: ['address'] // , 'geocode', 'establishment', 'regions'] 
                }}  
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}>

                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <Grid container
                    direction="column"
                    justify="center"
                    alignItems="stretch">
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        ref={inputSearch} 
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        //type='input' 
                        onKeyPress={(e) => suggestions.length > 0 && handleKeySelect(suggestions[0], e)}
                        {...getInputProps({ placeholder: "Buscar…" })} 
                        inputProps={{ 'aria-label': 'search', placeholder: "Buscar...." }} />
                    </div> 

                    {loading ? 
                      <Paper className={classes.containerResults}>
                        <Grid container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        className={classes.loadingContainer}>
                          <Typography className={classes.typography}>Buscando...</Typography>
                        </Grid>
                      </Paper> :
                      (suggestions.length > 0 ? 

                        <Paper className={classes.containerResults}>
                          <Grid container
                          direction="column"
                          justify="center"
                          alignItems="stretch"
                          className={classes.listContainer}>

                          {suggestions.map((suggestion, index) => {
                            const style = {
                              backgroundColor: suggestion.active ? "#29437488" : "#fff",
                              color: suggestion.active ? "#fff" : "#33658a", 
                              marginBotom: '8px',
                              paddingLeft: '8px',
                              width: '97.8%', 
                            };
                            //console.log('suggestion', suggestion)
                            //{...getSuggestionItemProps(suggestion, { style })}
                            return ( 
                              <Typography  
                                component="a"
                                key={index.toString()}  
                                href="#" 
                                noWrap 
                                {...getSuggestionItemProps(suggestion, )}
                                style={style} 
                                className={classes.selectItem} 
                                >{suggestion.description}</Typography>
                            )
                         })} 
                         </Grid>
                        </Paper> 
                         :
                        null)} 
                  </Grid>
                )}
              </PlacesAutocomplete>
            
            </Grid>   
      </Toolbar>
    </AppBar> 
  ); 
};
 

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};
 