import { createMuiTheme } from "@material-ui/core/styles";

const primary = "#33658A";
const secondary = "#234374";
const warning = "#88d4b8";
const success = "#3CD4A0";
const info = "#9013FE";
const lightenRate = 7.5;
const darkenRate = 15;

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    
    fontFamily: [
      'Century-Gothic', 
    ].join(','), 
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme, 
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        [theme.breakpoints.up("md")]: {
          marginLeft: theme.spacing(1),
        },
      },
      indicator: {
        height: 4,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        //background: 'green',
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("sm")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#babcc0",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiSlider: {
      root:{ 
        zIndex: theme.zIndex.drawer + 20000,
      },
      thumb:{
        color: "#86bbd8", 
      },
      track: {
        color: "#86bbd8",
      },
      rail: {
        color: "#86bbd8", 
      },
      markLabel: {
        color: '#86bbd8'
      },
      markLabelActive:  {
        color: '#86bbd8'
      }, 

    },
    MuiRadio: { 
      root: {
        color: '#fff',
        '&$checked': {
          color: '#86bbd8',
        },
      },
      checked: {},
    },
  },

  
};

export default theme;
