import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  
  appBarShift: {
    backgroundColor: "#232f3e",
    flex: 'display',
    justifyContent: 'space-between',
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }, 

  rootList: {
    padding: theme.spacing(0, 2),  
  },
  footerList: {
    padding: theme.spacing(0, 2),  
    marginBottom: theme.spacing(2),
  },
 

  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #33658a inset",
    paddingTop: theme.spacing(2), 
    paddingBottom: theme.spacing(2),
    height: theme.spacing(7), 
    color: theme.palette.common.white,
  }, 
  otherItem: {
    paddingTop: theme.spacing(3), 
    paddingBottom: theme.spacing(2),
    //height: theme.spacing(7), 
    //color: theme.palette.common.white,
  },
  slider:{
    display: 'flex',
    margin: theme.spacing(3, 0),
    //zIndex: 50, 

  },
  menuTitle: {
    color: '#fff'
    //fontSize
  },
  icon: {
    color: '#fff',
    height: theme.spacing(3), 
    width: theme.spacing(3), 
  }
  
 

}));

export default useStyles;
