import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid'; 
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
//CONTEXT
import { useMarket } from '../../context/market';
import { useUser } from '../../context/auth'
//IMG
import CiduLogo from '../../assets/img/ciduBlanco.png';
//STYLES
import useStyles from './style'


export default function Navigator(props){

  const classes = useStyles();
  const { staticContext, open,  history, isDesktop,  ...rest } = props; 
  const { logout } = useUser();
  const { marketData, setMarketData } = useMarket(); 
  const [marks, SetMarks] = useState([
    {
      value: 1,
      label: '0 m', 
    }, 
    {
      value: 2500,
      label: '2.5 Km'
    },
  ])

  const handleRadioChange = (e, newValue) => {
    const value = newValue
    //console.log('value', value) 
    setMarketData(prevState => ({
      ...prevState,
      ratio: value
    }))
  }

  const handleTypeChange = (e, newValue) => {
    const value = newValue
    //console.log('value', value) 
    setMarketData(prevState => ({
      ...prevState,
      type: value
    }))
  }
  
  const handleClassChange = (e, newValue) => {
    const value = newValue
    //console.log('value', value) 
    setMarketData(prevState => ({
      ...prevState,
      class: value
    })) 
  }

  const handleAgeChange = (e, newValue) => {
    const value = newValue
    //console.log('value', value) 
    setMarketData(prevState => ({
      ...prevState,
      age: value
    })) 
  }

  const handleAreaChange = (e, newValue) => {
    const value = newValue
    //console.log('value', value) 
    setMarketData(prevState => ({
      ...prevState,
      area: value
    })) 
  }

  function hanldleValueText(value) {
    return `${value} m`;
  }

  const handleExitApp = async () => {
    try { 
      await logout() 
    } catch (e) {
      console.log('error to exit from app', e)
    }
  }

  return (
    <Drawer
      anchor="right"
      PaperProps={{ className: classes.appBarShift}}
      open={isDesktop ? true : open} 
      {...rest}> 
      
      <List disablePadding className={classes.rootList}>
        <ListItem className={classes.itemCategory}>
          <Grid item
            container
            direction="row"
            justify="center"
            alignItems="center" >
            <Avatar alt="cidu" src={CiduLogo} />
          </Grid>
        </ListItem>
        <ListItem className={classes.otherItem}>
          <Grid item
            container
            direction="column"
            justify="center"
            alignItems="stretch" >
            <Typography noWrap variant="h6" component="h5" align={'left'} className={classes.menuTitle}>Radio</Typography>
            <Slider 
              className={classes.slider} 
              min={marketData.minRatio}
              max={marketData.maxRatio}
              value={marketData.ratio} 
              onChange={handleRadioChange}
              getAriaValueText={hanldleValueText} 
              valueLabelDisplay="on" 
              step={100}
              marks={marks} />
          </Grid>
        </ListItem>
        <ListItem className={classes.otherItem}>
          <Grid item
            container
            direction="column"
            justify="center"
            alignItems="stretch" >
            <Typography noWrap variant="h6" component="h5" align={'left'} className={classes.menuTitle}>Tipo</Typography>
            <FormControl>
              <RadioGroup aria-label="gender" name="gender1" value={marketData.type} onChange={handleTypeChange}>
                <FormControlLabel className={classes.menuTitle} value="casa" control={<Radio color='primary' />} label="Casa" />
                <FormControlLabel className={classes.menuTitle} value="apto" control={<Radio color='primary' />} label="Apartamento" />
                <FormControlLabel className={classes.menuTitle} value="otro" control={<Radio color='primary' />} label="Otro" /> 
                <FormControlLabel className={classes.menuTitle} value="todo" control={<Radio color='primary' />} label="Todos" /> 
              </RadioGroup>
            </FormControl>
          </Grid>
        </ListItem>
        <ListItem className={classes.otherItem}>
          <Grid item
            container
            direction="column"
            justify="center"
            alignItems="stretch" >
            <Typography noWrap variant="h6" component="h5" align={'left'} className={classes.menuTitle}>Clase</Typography>
            <FormControl>
              <RadioGroup aria-label="gender" name="gender1" value={marketData.class} onChange={handleClassChange}>
                <FormControlLabel className={classes.menuTitle} value="nph" control={<Radio color='primary'/>} label="Nph" /> 
                <FormControlLabel className={classes.menuTitle} value="ph" control={<Radio color='primary' />} label="Ph" />
                <FormControlLabel className={classes.menuTitle} value="todo" control={<Radio color='primary' />} label="Todos" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </ListItem>
        <ListItem className={classes.otherItem}>
          <Grid item
            container
            direction="column"
            justify="center"
            alignItems="stretch" >
            <Typography noWrap variant="h6" component="h5" align={'left'} className={classes.menuTitle}>Edad</Typography>
            <FormControl>
              <RadioGroup aria-label="gender" name="gender1" value={marketData.age} onChange={handleAgeChange}>
                <FormControlLabel className={classes.menuTitle} value="one" control={<Radio color='primary'/>} label="0-5 años" /> 
                <FormControlLabel className={classes.menuTitle} value="two" control={<Radio color='primary' />} label="5-10 años" />
                <FormControlLabel className={classes.menuTitle} value="three" control={<Radio color='primary' />} label="10-20 años" />
                <FormControlLabel className={classes.menuTitle} value="four" control={<Radio color='primary' />} label="> 20 años" />
                <FormControlLabel className={classes.menuTitle} value="all" control={<Radio color='primary' />} label="Todos" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </ListItem>
        <ListItem className={classes.otherItem}>
          <Grid item
            container
            direction="column"
            justify="center"
            alignItems="stretch" >
            <Typography noWrap variant="h6" component="h5" align={'left'} className={classes.menuTitle}>Área</Typography>
            <FormControl>
              <RadioGroup aria-label="gender" name="gender1" value={marketData.area} onChange={handleAreaChange}>
                <FormControlLabel className={classes.menuTitle} value="one" control={<Radio color='primary'/>} label="0-50 m2" /> 
                <FormControlLabel className={classes.menuTitle} value="two" control={<Radio color='primary' />} label="50-100 m2" />
                <FormControlLabel className={classes.menuTitle} value="three" control={<Radio color='primary' />} label="100 - 150 m2" />
                <FormControlLabel className={classes.menuTitle} value="four" control={<Radio color='primary' />} label="> 150 m2" />
                <FormControlLabel className={classes.menuTitle} value="all" control={<Radio color='primary' />} label="Todos" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </ListItem>
      </List>



      <List disablePadding className={classes.footerList}>
        <ListItem button onClick={handleExitApp}>
          <ListItemIcon><ExitToAppIcon className={classes.icon}/></ListItemIcon> 
          <ListItemText className={classes.menuTitle}>Salir</ListItemText>
        </ListItem>  
      </List>
    </Drawer>
  ) 
} 