import React, { useState, createContext, useContext, useMemo } from 'react';

const MarketContext = createContext();

export function MarketProvider(props) {

  const [coordinates, setCoordinates] = useState({
      lat: 4.65855495640821, //4.637600, 
      lng: -74.09351348876953, //-74.062531, 
    })  

  const [marketData, setMarketData] = useState({
    nameSite: '',
    ratio: 800,
    minRatio: 0,
    maxRatio: 2500,
    type: 'todo',
    class: 'todo', 
    age: 'all',
    area: 'all',
  })

  const updateMarket = (data) => {
    const marketInfo = data;
    setMarketData( prevState => ({
      ...prevState,
      nameSite: marketInfo, 
    }))
  }

  const value = useMemo(() => {
    return({
      coordinates,
      setCoordinates, 
      marketData,
      setMarketData,
      updateMarket
    })
  }, [coordinates, marketData])

  return <MarketContext.Provider value={value} {...props}/>  
}

// Custom hook that shorthands the context!
export function useMarket(){
  const context = useContext(MarketContext)
  if (!context){
    throw new Error("useMarket debe estar dentro del proveedor");
  }
  return context
}