import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { Auth } from '../firebase';
//Get user data from DB
import { getData } from '../lib/fetchDatabase'; 

const UserContext = createContext();

export function UserProvider(props) {
  const [user, setUser] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true) // Helpful, to update the UI accordingly.  

  useEffect(() => {
    // Listen authenticated user
    const unsubscriber = Auth.onAuthStateChanged(async (user) => {
      try {
        if (user && !user.isAnonymous) { 
          const { uid, displayName, email, photoURL, emailVerified} = user;
          const userDoc = await getData('ciduUsers', user.uid); 
          setUser({ uid, displayName, email, photoURL, emailVerified, userDoc });
        } else setUser(null)
      } catch (e) { console.log("Error from UserContext: ", e)
      } finally {
        setLoadingUser(false)
      }
    })

    // Unsubscribe auth listener on unmount
    return () => unsubscriber()
  }, [])
  
  const logout = async () => {
    try{ 
      await Auth.signOut()  
    }catch(e){
      console.error('Error from context: ', e)
    } 
  }

  const value = useMemo(() => {
    return({
      user,
      setUser,
      loadingUser,
      logout
    })
  }, [user, loadingUser])

  return <UserContext.Provider value={value} {...props}/>  
}

// Custom hook that shorthands the context!
export function useUser(){
  const context = useContext(UserContext)
  if (!context){
    throw new Error("useUser debe estar dentro del proveedor");
  }
  return context
}

