import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
//STYLES
import { ThemeProvider } from "@material-ui/styles";
import theme from "../theme";
//COMPONENTS
import Root from "../components/root";
import NoFound from "../pages/noFound";
import Login from "../pages/login";
import Dashboard from '../pages/dashboard'; 
import SafeRoute from '../components/safeRoute';
//USER CONTEXT
import { UserProvider } from '../context/auth'; 
import { MarketProvider } from '../context/market'; 
//
export default function Routes() {
  return ( 
    <ThemeProvider theme={theme}> 
      <UserProvider>
        <MarketProvider>
          <Root>   
            <Router>
              <Switch>
                <SafeRoute type='private' exact path="/mapa" component={Dashboard} /> 
                <SafeRoute type='public' exact path='/' component={Login} />
                <Route type='public' component={NoFound} />
              </Switch>
            </Router> 
          </Root>
        </MarketProvider>
      </UserProvider> 
    </ThemeProvider>
  )
} 