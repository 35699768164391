import { fade, makeStyles } from "@material-ui/core/styles";

const lightColor = 'rgba(255, 255, 255, 0.7)';
const drawerWidth = 256;
 
const useStyles = makeStyles((theme) => ({
  appbarcololor: {
    background: '#33658a',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    background: '#33658a',
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },


  toolbar: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: "center",
    [theme.breakpoints.down('xs')]: { 
      justifyContent: 'center',
    },
 
  },


  menuButton: {
    marginRight: -theme.spacing(1), 
    [theme.breakpoints.down('xs')]: {
      marginRight: -theme.spacing(0),
      marginLeft: theme.spacing(0.5),  
    },
    [theme.breakpoints.up('md')]: { 
      display: 'none' 
    },
  },
  headerSpace: {
    marginLeft: theme.spacing(1), 
    [theme.breakpoints.down('sm')]: { 
      display: 'none' 
    },
  },
  tittleSlide: {
    fontFamily: 'Century-Gothic', 
    [theme.breakpoints.down('sm')]: {
      display: 'none' 
    },
  },
  containerSearch:{
    zIndex: 10,
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%', 
    },
 

  },

  iconButtonAvatar: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none', 
      margin: theme.spacing(0),
    }, 
  },
  link: {
    textDecoration: 'none',
    color: '#33658a',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  }, 

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
    },
  },

  containerResults: {
    width: '350px', 
    position: 'absolute',
    marginLeft: theme.spacing(2),
    top: '44px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      width: '100%',
      marginLeft: theme.spacing(0),
      left: '0px',
      top: '52px',
      borderRadius: 0,
    },
  },
  loadingContainer: {
    margin: theme.spacing(2, 1),
  },
  listContainer: {
    margin: theme.spacing(2, 0),
  },
  selectItem: {
    paddingTop: theme.spacing(1),
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: "#29437488 !important",
      color: "#fff", 
    },
  }

  

  
}));
export default useStyles;