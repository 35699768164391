import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    overflow: 'hidden',
    '& > *': { 
      height: '100% !important',
    }, 
    background: '#e4e4e4',
    height: '100%',  
    [theme.breakpoints.up('md')]: {
      marginRight: drawerWidth, 
    },
  }, 


  fab:{
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(2),
    background: '#294374',
    color: '#fff'
  },



  rootMap: {
    display: "flex",
    flexDirection: 'column',
    backgroundColor: "#232529",
    color: "white",
    fontFamily: "Century-Gothic",
    height: "100%",
  },

  rootCard: {
    display: "flex",
    position: 'absolute',
    width: '25%',
    top: '70px',
    left: '16px',
    zIndex: '20',
    [theme.breakpoints.down('xs')]: {
      width: '85%', 
    },
  },







  infoView:{
    color: '#294374'
  },


  //Bottom Table
  rootContainer:{
    display: "flex",
    backgroundColor: "#e4e4e4",
    color: "white",
    height: "30%",
    overflowY: 'auto',
  },
  tableContainer: {
    //marginTop: theme.spacing(2),
    //marginBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  footerSpace:{
    height: theme.spacing(1),
  },
  row: {
    cursor: 'pointer'
  }


}));
export default useStyles;
